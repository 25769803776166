import "lforms/dist/lforms/webcomponent/assets/lib/zone.min.js";
import "lforms/dist/lforms/webcomponent/runtime.js";
import "lforms/dist/lforms/webcomponent/polyfills.js";
import "lforms/dist/lforms/webcomponent/main.js";
import "lforms/dist/lforms/fhir/R4/lformsFHIR.min.js";
import { Step } from "../types";
import { sleep } from "../../utils/miscellaneous";
import { applyMasks } from "../../utils/maskHelper";

// Placeholder declaration for LForms
declare const LForms: any;

export const shapeFormWithDraftData = (formDefTmp: any, draft: unknown) => {
  let formWithDraftData = null;
  let lformsQ = LForms.Util.convertFHIRQuestionnaireToLForms(formDefTmp, "R4");
  formWithDraftData = lformsQ;

  if (draft) {
    formWithDraftData = LForms.Util.mergeFHIRDataIntoLForms(
      "QuestionnaireResponse",
      draft,
      lformsQ,
      "R4"
    );
  }

  return formWithDraftData;
};

export const shapeLoginData = (
  step: Step,
  sessionId: string,
  verificationCodes: string,
  patientId: number,
  phoneNumber: string,
  refParams: any
) => {
  let loginData = {
    sessionId: sessionId,
    verificationCode: verificationCodes,
    pid: patientId,
    ...refParams,
    phoneNumber: phoneNumber,
  };

  if (step === Step.QUESTIONNAIRE_FORM) {
    loginData = {
      ...loginData,
      patientWillBringPassport: true,
    };
  }
  return loginData;
};

export const addEmergencyContactFieldListener = async (
  form: HTMLDivElement
) => {
  const emergencyContactNameFieldLinkId = "4888257147126";
  const emergencyContactNameField = form.querySelector(
    `[id="${emergencyContactNameFieldLinkId}/1/1"]`
  );
  if (emergencyContactNameField) {
    emergencyContactNameField.addEventListener("keyup", async () => {
      await sleep(1000);
      applyMasks(form);
    });
  }
};
