export const masks: Record<string, string> = {
  '[id="4851389320111/1/1"]': "___-___-____",
  '[id="4257049322399/1/1"]': "___-___-____",
  '[id="9780511808549/1/1"]': "___-___-____",
  '[id="2220202913158/1/1"]': "___-___-____",
  '[id="6924382606413/1/1"]': "___-__-____",
  '[id="7712429938391/1/1"]': "_____",
  // '[placeholder="MM/DD/YYYY"]': '__/__/____',
};

//format to use mask format, while typing
export function formatPhoneNumber(value: string): string | null {
  let cleaned = ("" + value).replace(/\D/g, "");
  //remove more then 10 digits
  cleaned = cleaned.substring(0, 10);
  //add zeros to the end to make sure we have 10 digits
  const zeros = "0000000000";
  const addedZeros = zeros.length - cleaned.length;
  const cleanedWithZeros =
    cleaned + zeros.substring(0, zeros.length - cleaned.length);
  const match = cleanedWithZeros.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const formatted = "" + match[1] + "-" + match[2] + "-" + match[3];
    const hyphenCount = addedZeros <= 4 ? 0 : addedZeros <= 7 ? 1 : 2; //addedZeros > 0 ? 2 : (addedZeros > -4 ? 1 : 0);
    //remove zeros from the end
    let formattedWithoutZeros = formatted.substring(
      0,
      formatted.length - addedZeros - hyphenCount
    );
    //remove hyphens from the end
    formattedWithoutZeros = formattedWithoutZeros.replace(/-+$/, "");
    return formattedWithoutZeros;
  }
  return null;
}

function findCaretPosition(pattern: string, x: string): number {
  let digits: string = x.replace(/[^0-9]/g, "");
  if (digits.length === 0) return 0;
  let placeholderSymbolCount = 0;

  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] === "_") {
      placeholderSymbolCount++;
    }
    if (placeholderSymbolCount === digits.length) {
      return i + 1;
    }
  }
  return pattern.length;
}

export function getFormattedInputValue(
  currentInputTextValue: string,
  mask: string
) {
  const digits = currentInputTextValue.replace(/[^0-9]/g, "");
  let count = 0;

  let formattedInputTextValue = "";
  for (let i = 0; i < mask.length; i++) {
    const patternSymbol = mask[i];

    if (digits[count]) {
      if (patternSymbol === "_") {
        formattedInputTextValue += digits[count];
        count++;
      } else {
        formattedInputTextValue += patternSymbol;
      }
    } else {
      formattedInputTextValue += mask.split("")[i];
    }
  }
  return formattedInputTextValue;
}

export function applyMasks(form: HTMLDivElement) {
  Object.entries(masks).forEach(([selector, mask]) => {
    const inputs = form.querySelectorAll(selector);

    if (!inputs) return;
    inputs.forEach((input) => {
      if (input.getAttribute("data-mask")) return;
      input.setAttribute("data-mask", mask);

      handleFocus(input as HTMLInputElement, mask);
    });
  });
}

function handleFocus(input: HTMLInputElement, mask: string) {
  const localKeyUpHandler = () => {
    keyUpHandler(input, mask);
  };
  input.addEventListener("keyup", localKeyUpHandler);
}

export function keyUpHandler(input: HTMLInputElement, mask: string) {
  const val = findCaretPosition(mask, input.value);
  const newValue = getFormattedInputValue(input.value, mask);
  if (newValue === input.value) return;
  input.value = newValue;
  const event = new CustomEvent("updateInputValue", {
    detail: { componentId: input.id, newValue: newValue },
  });
  document.dispatchEvent(event);
  if (input.selectionStart) {
    input.focus();
    input.setSelectionRange(val, val);
  }
}
