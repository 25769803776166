import { Typography } from "@mui/material";
import { FC } from "react";
import Form from "../Form/Form";
import { LoopDocumentRetriever } from "../LoopDocumentRetriever/LoopDocumentRetriever";
import PersonalDataAuth from "../PersonalDataAuth/PersonalDataAuth";
import PhoneLogin from "../Authorization/PhoneLogin";
import Prompt from "../Prompt";
import { PersonInfo, Step } from "../types";
import VerificationCode from "../Authorization/VerificationCode";
import { PassportPhotoPickerComponent } from "../PhotoPassportComponent/PassportPhotoPickerComponent";
import { PassportReminder } from "../PassportReminder/PassportReminder";
import { usePatientDataContext } from "../../contexts/PatientDataContext";
import { useAppContext } from "../../contexts/AppContext";
import { SendVerificationCode } from "../Authorization/SendVerificationCode";

type StepComponentProps = {
  submitForm?: (data: any) => void;
  login?: (data: any) => Promise<void>;
  loginWithPhone?: (phoneNumber: string) => Promise<void>;
  loginWithPersonalInfo?: (personInfo: PersonInfo) => Promise<void>;
  loginWithSendCode?: (sendCode: boolean) => Promise<void>;
  loginWithVerificationCode?: (verificationCode: string) => Promise<void>;
  setStep: (data: any) => void;
  promptInfo?: any;
  isMobile?: boolean;
};

export const GetStepComponent: FC<{
  step: Step;
  props: StepComponentProps;
}> = ({ step, props }) => {
  const { sessionId, verificationCodes, patientId, phoneNumber } =
    usePatientDataContext();
  const { isKioskMode } = useAppContext();

  switch (step) {
    case Step.DOCUMENTS_FORM:
      return <LoopDocumentRetriever />;
    case Step.PASSPORT_FORM:
      return <PassportPhotoPickerComponent setStep={props.setStep} />;
    case Step.PASSPORT_REMINDER:
      return <PassportReminder setStep={props.setStep} />;
    case Step.NEW_PROFILE_FORM:
    case Step.QUESTIONNAIRE_FORM:
      if (props.submitForm && props.login) {
        return (
          <Form
            onSubmit={props.submitForm}
            step={step}
            onInitialization={props.login}
          />
        );
      }
      return <></>;
    case Step.QUESTIONNAIRE_SUBMITTED_SUCCESSFULLY:
      return (
        <>
          <Typography variant="h6">Thank you!</Typography>
          <Typography>
            Your information has been submitted successfully.
          </Typography>
        </>
      );
    case Step.QUESTIONNAIRE_SUBMITTED_ERROR:
      return (
        <>
          <Typography variant="h6">Oops!</Typography>
          <Typography>Something went wrong. Please try again later.</Typography>
        </>
      );
    case Step.PHONE_AUTHORIZATION:
      if (props.loginWithPhone) {
        return <PhoneLogin onLogin={props.loginWithPhone} />;
      }
      return <></>;
    case Step.SEND_VERIFICATION_CODE:
      if (!props.loginWithSendCode) return <></>;
      return (
        <SendVerificationCode
          onLogin={props.loginWithSendCode}
          phoneNumberLast4={props.promptInfo.phoneNumberLast4}
        />
      );
    case Step.VERIFICATION_CODE:
      if (!props.promptInfo || !props.loginWithVerificationCode) return <></>;
      return (
        <VerificationCode
          onLogin={props.loginWithVerificationCode}
          promptInfo={props.promptInfo}
        />
      );
    case Step.PERSONAL_DATA:
      if (!props.loginWithPersonalInfo) return <></>;
      return (
        <PersonalDataAuth
          onLogin={props.loginWithPersonalInfo}
          promptInfo={props.promptInfo}
        />
      );
    case Step.PROMPT:
      if (!props.promptInfo || !props.setStep) return <></>;
      let onOkAction: (() => void) | undefined = undefined;
      if (
        props.promptInfo.questionnaire_submitted &&
        props.promptInfo.authorized &&
        isKioskMode
      ) {
        onOkAction = () => {
          if (!props.login) return;

          props.login({
            sessionId: sessionId,
            verificationCode: verificationCodes,
            pid: patientId,
            phoneNumber: phoneNumber,
          });
        };
      }

      return (
        <Prompt
          onSwitchStep={props.setStep}
          promptInfo={props.promptInfo}
          onOkAction={onOkAction}
        />
      );
    default:
      return <></>;
  }
};
