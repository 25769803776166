import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useRef,
} from "react";

interface AppErrorContextValue {
  getUpToDateErrorMessage: () => string;
  openErrorModal: boolean;
  openErrorDialog: (message: string) => void;
  handleCloseErrorModal: () => void;
}

const AppErrorContext = createContext<AppErrorContextValue | undefined>(
  undefined
);

export const useAppErrorContext = (): AppErrorContextValue => {
  const context = useContext(AppErrorContext);
  if (!context) {
    throw new Error("useAppErrorContext must be used within an ErrorProvider");
  }
  return context;
};

interface ErrorProviderProps {
  children: ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const errorMessageRef = useRef<string>("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const getUpToDateErrorMessage = useCallback(() => {
    return errorMessageRef.current;
  }, []);

  const openErrorDialog = useCallback((message: string) => {
    errorMessageRef.current = message;
    setOpenErrorModal(true);
  }, []);

  const handleCloseErrorModal = useCallback(() => {
    errorMessageRef.current = "";
    setOpenErrorModal(false);
  }, []);

  const contextValue: AppErrorContextValue = {
    getUpToDateErrorMessage,
    openErrorModal,
    openErrorDialog,
    handleCloseErrorModal,
  };

  return (
    <AppErrorContext.Provider value={contextValue}>
      {children}
    </AppErrorContext.Provider>
  );
};
