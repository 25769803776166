import { BrowserRouter, Route, Routes } from "react-router-dom";

import { App } from "./App";
import { PersonalLinkProvider } from "./contexts/PersonalLinkContext";
import { QuestionnaireDraftProvider } from "./contexts/QuestionnaireDraftContext";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <PersonalLinkProvider>
        <QuestionnaireDraftProvider>
          <Routes>
            <Route path="/a/:aId/p/:pId" element={<App />} />
            <Route path="/a/:aId" element={<App />} />
            <Route path="/p/:pId" element={<App />} />
            <Route index path="/:termPolicy?" element={<App />} />
          </Routes>
        </QuestionnaireDraftProvider>
      </PersonalLinkProvider>
    </BrowserRouter>
  );
};
