import { useAppErrorContext } from '../contexts/AppErrorContext'
import { usePatientDataContext } from '../contexts/PatientDataContext'
import { fetchData } from '../api/apiService'
import { UNEXPECTED_ERROR_MSG } from '../components/stringConstants'
import { Status, SubmissionInfo } from '../components/types'

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function useDocumentFetchingInfrastructure(
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setResponseDocument: React.Dispatch<React.SetStateAction<any>>,
  setShowSubmissionInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLastDocument: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmissionInfo: React.Dispatch<React.SetStateAction<SubmissionInfo | null>>,
  setShowBtnRetryRequest: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const { sessionId, verificationCodes, patientId, contractId, facilityId } =
    usePatientDataContext()
  const { openErrorDialog } = useAppErrorContext()

  const getDocument = async () => {
    window.scrollTo(0, 0)
    let responseData: any = {}
    setGlobalLoading(true)
    setResponseDocument(null)
    try {
      responseData = await fetchData('POST', `/npp/get-document`, {
        sessionId: sessionId,
        verificationCode: verificationCodes,
        pid: patientId,
        contractId: contractId,
        facilityId: facilityId,
      })
    } catch (error) {
      setGlobalLoading(false)
      openErrorDialog(UNEXPECTED_ERROR_MSG)
    }

    if (!responseData.data) return

    if (responseData.data.errorMessage) {
      openErrorDialog(responseData.data.errorMessage)
      setGlobalLoading(false)
      setShowBtnRetryRequest(true)
      return
    }
    if (responseData.data.isLastDocument) {
      setIsLastDocument(true)
      // after submission of last document need some time to finish its animation
      await delay(2800)
      setShowSubmissionInfo(true)
      setSubmissionInfo({
        mainMessage: responseData.data.successMessage,
        status: Status.success,
      })
      return
    }
    const data = responseData.data
    const docResponse = {
      id: data.document.id,
      documentHTML: data.document.html,
      title: data.document.title,
      jsonData: data.jsonData,
    }
    setResponseDocument(docResponse)
    setShowBtnRetryRequest(false)
    setGlobalLoading(false)
  }
  return [getDocument]
}
