import { FC } from "react";

import { Dialog } from "@mui/material";

import { Status } from "../types";
import { useAppErrorContext } from "../../contexts/AppErrorContext";
import { InformationDisplayer } from "../InformationDisplayer/InformationDisplayer";

export const ModalError: FC = () => {
  const { getUpToDateErrorMessage, openErrorModal, handleCloseErrorModal } =
    useAppErrorContext();
  const errorMessage = getUpToDateErrorMessage();
  return (
    <>
      {errorMessage && (
        <Dialog open={openErrorModal} onClose={handleCloseErrorModal}>
          <InformationDisplayer
            info={{
              status: Status.error,
              mainMessage: errorMessage,
            }}
            action={{ text: "Close", clickHandler: handleCloseErrorModal }}
          />
        </Dialog>
      )}
    </>
  );
};
