import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { FC } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { SubmissionInfo, Status } from '../types'

const iconMapping = {
  [Status.success]: <CheckOutlinedIcon color='success' fontSize='large' />,
  [Status.error]: <ErrorOutlineIcon color='error' fontSize='large' />,
  [Status.info]: <InfoOutlinedIcon color='secondary' fontSize='large' />,
}

const getIconForStatus = (status: Status) => {
  return iconMapping[status] || null
}

type Action = {
  text: string
  clickHandler: () => void
  disabled?: boolean
}

export type InformationDisplayerProps = {
  info: SubmissionInfo
  action?: Action
  children?: React.ReactNode
  title?: string
  btnVariant?: 'contained' | 'outlined' | 'text'
}

export const InformationDisplayer: FC<InformationDisplayerProps> = ({
  info,
  action,
  children,
  title,
  btnVariant,
}) => {
  const { status, mainMessage, details } = info
  const icon = getIconForStatus(status)
  return (
    <>
      <Box>
        <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <div
            style={{
              marginLeft: '10px',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              textTransform: 'capitalize',
            }}
          >
            {title ?? status}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{whiteSpace: 'pre-line', lineHeight: '1.5'}}>
            <span>{mainMessage}</span>
          </DialogContentText>
          <DialogContentText>{details}</DialogContentText>
          {children}
        </DialogContent>
        {action && (
          <DialogActions>
            <Button
              onClick={action.clickHandler}
              color='primary'
              disabled={action.disabled}
              variant={btnVariant ?? 'text'}
            >
              {action.text}
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  )
}
