import React, { FC } from "react";
import { GetStepComponent } from "../GetStepComponent/GetStepComponent";
import { PersonInfo, Step } from "../types";
import { useAppContext } from "../../contexts/AppContext";
import "./UnauthorizedComponent.css";

type UnauthorizedComponentProps = {
  login?: (data: any) => Promise<void>;
  loginWithPhone?: (phoneNumber: string) => Promise<void>;
  loginWithPersonalInfo?: (personInfo: PersonInfo) => Promise<void>;
  loginWithSendCode?: (sendCode: boolean) => Promise<void>;
  loginWithVerificationCode?: (verificationCode: string) => Promise<void>;
  setStep: (data: any) => void;
  promptInfo?: any;
};

export const UnauthorizedComponent: FC<{
  step: Step;
  props: UnauthorizedComponentProps;
}> = ({ step, props }) => {
  const { isMobile } = useAppContext();

  return (
    <div
      style={{
        padding: isMobile ? "0" : "20px",
        marginTop: "20px",
        flex: "1",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <GetStepComponent step={step} props={props} />
    </div>
  );
};
