import React, {
  createContext,
  useContext,
  ReactNode,
  useRef,
  useCallback,
} from "react";

interface QuestionnaireDraftContextValue {
  questionnaireDraftRef?: any;
  setQuestionnaireDraft: React.Dispatch<React.SetStateAction<any>>;
}

const QuestionnaireDraftContext = createContext<
  QuestionnaireDraftContextValue | undefined
>(undefined);

export const useQuestionnaireDraftContext =
  (): QuestionnaireDraftContextValue => {
    const context = useContext(QuestionnaireDraftContext);
    if (!context) {
      throw new Error(
        "useQuestionnaireDraftContext must be used within an QuestionnaireDraftProvider"
      );
    }
    return context;
  };

interface QuestionnaireDraftProviderProps {
  children: ReactNode;
}

export const QuestionnaireDraftProvider: React.FC<
  QuestionnaireDraftProviderProps
> = ({ children }) => {
  const questionnaireDraftRef = useRef<any>({});

  const setQuestionnaireDraft = useCallback((newValue: any) => {
    questionnaireDraftRef.current = newValue;
  }, []);

  const contextValue: QuestionnaireDraftContextValue = {
    questionnaireDraftRef,
    setQuestionnaireDraft
  };

  return (
    <QuestionnaireDraftContext.Provider value={contextValue}>
      {children}
    </QuestionnaireDraftContext.Provider>
  );
};
